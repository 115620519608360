import * as React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/DarkHeader';

const NotFoundPage = () => {
  const pageTitle = '404';
  const title = 'OOPS!';
  const description = '404 - PAGE NOT FOUND';
  return (
    <Layout
      pageTitle={pageTitle}
      pageDescription={description}
      noindex={true}
      nofollow={true}
    >
      <PageHeader title={title} description={description}>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8"></div>
        </div>
      </PageHeader>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
